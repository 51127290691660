<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <el-input class="searchInput" clearable v-model="searchQuery.name" size="small" placeholder="请输入联系人"></el-input>
        <el-input class="searchInput" clearable v-model="searchQuery.company_name" size="small" placeholder="请输入企业名称"></el-input>
        <el-input class="searchInput" clearable v-model="searchQuery.mobile" size="small" placeholder="请输入账号(注册手机号)"></el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange"
          >查询</el-button
        >
       
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        stripe
        :border="true"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column label="联系人" :show-overflow-tooltip="true" >
          <template slot-scope="scope">
            <el-link class="link"  @click="liaison(scope.row)" :underline="false" type="primary">{{scope.row.name_zh || scope.row.name_en | textFormat }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="账号" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.mobile | textFormat }}</template>
        </el-table-column>
        <el-table-column label="邮箱" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.email | textFormat }}</template>
        </el-table-column>
        <el-table-column label="企业名称" :show-overflow-tooltip="true" width="250" >
          <template slot-scope="scope">
            <template v-if="scope.row.is_bind_company == 1" >
              <el-link class="link"  @click="editCompany(scope.row)" :underline="false" type="primary">{{scope.row.company_name_zh || scope.row.company_name_en | textFormat }}</el-link>
              <span class="tips" v-if="scope.row.company_status != 2" @click="editCompany(scope.row)">待认证</span>
            </template>
            <el-button type="text" v-else @click="bindCompany(scope.row)" class="tips">待设置</el-button>
          </template>
        </el-table-column>
        <el-table-column label="地区" :show-overflow-tooltip="true" >
          <template slot-scope="scope">{{scope.row.region_zh || scope.row.region_en | textFormat }}</template>
        </el-table-column>
        <el-table-column label="企业专属客服" :show-overflow-tooltip="true" width="120">
          <template slot-scope="scope">{{scope.row.company_service_name | textFormat }}</template>
        </el-table-column>
        <el-table-column label="签约状态" :show-overflow-tooltip="true" width="120">
          <template slot-scope="scope">
            <span :class="scope.row.is_blacklist ? 'red':''">{{ scope.row.sign_status | signStatusFormat }} {{ scope.row.is_blacklist ? '，不良信用':'' }}</span>  
          </template>
        </el-table-column>
        <el-table-column label="账号状态" :show-overflow-tooltip="true" width="120" >
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未激活</span>
            <span v-else-if="scope.row.status == 1">已激活</span>
            <span v-else-if="scope.row.status == 2">禁用</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <div slot-scope="scope">
             <el-button type="text" @click="customerBooking(scope.row)" :disabled="scope.row.company_status != 2"
              >客户订舱</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>


    <el-dialog title="绑定企业" :visible.sync="dialogShow" width="1200px">
      <yetJoin :generationBinding="true" :bind_user_id="bind_user_id" @changeCompany="companyStats"></yetJoin>
    </el-dialog>
    <el-dialog title="查看资料" :visible.sync="dialogShowUser" width="1200px">
      <userZhYmp ref="userZhYmpRef" :generationBinding="true" :bind_user_id="bind_user_id"></userZhYmp>
    </el-dialog>
  </div>
</template>

<script>
import yetJoin from "~bac/components/company/basic/yetJoin";
import userZhYmp from "~bac/components/userInfo/ZhYmp";
export default {
  name: "customerManage",
  components:{
    yetJoin,
    userZhYmp
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      dialogShowUser: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      searchQuery:{
        name:"",
        company_name:'', 
        mobile:'',
      },
      bind_user_id:''
    };
  },
  mounted() {
    this.$log({
      type: 2,
      topic: "ymp.workbench",
      id: "ymp.workbench.customer",
    });
    this.getList();
  },
  methods: {
    companyStats() {
      this.getList()
      this.dialogShow = false;
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        user_id: this.USER_INFO.id,
        nopage:0,
      };
      params = Object.assign(params, this.searchQuery)
      let result = await this.$store.dispatch( //
        "baseConsole/cooperative_client_list",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
    searchChange() {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.customer.search",
        content: JSON.stringify({
          concat: this.searchQuery.name || '',
          company: this.searchQuery.company_name || '',
          mobile: this.searchQuery.mobile || '',
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
    // 查看客户资料
    liaison(row){
      this.dialogShowUser = true;
      this.bind_user_id = row.id;
      this.$nextTick(()=>{
        this.$refs.userZhYmpRef.getUserInfo(true,row.id);
      })
    },
    // 编辑绑定企业
    editCompany(row){
      this.$router.push({
        query: {
          company_id: row.company_id,
          company_status: row.company_status
        },
        path: "/console/clients/companyInfo",
      })
    },
    // 绑定企业
    bindCompany(row){
      this.dialogShow = true;
      this.bind_user_id = row.id;
    },
    customerBooking(row) {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.customer.boking",
        content: JSON.stringify({
          id: row.id || ''
        }),
      });
      this.$router.push({
        query: {
          info: encodeURIComponent(JSON.stringify({
            company_id: row.company_id,
            user_id: row.id,
            name_zh: row.name_zh,
            company_name_zh: row.company_name_zh,
          }))
        },
        path: "/console/clients/booking",
      })
    },
  },
  filters:{
    signStatusFormat(type) {
      let str = "-";
      if (type == 1) {
        str = '未签约'
      } else if(type == 2){
        str = '月结协议'
      }else if(type == 3){
        str = '买单协议'
      }
      return str;
    },
  }
};
</script>
<style scoped lang="less">
.protMsg {
  display: flex;
  justify-content: space-between;
  .from {
    width: 100px;
    p{
      width: 100%;
    }
  }
  .jt {
    width: 50px;
    color: #027fff;
    line-height: 50px;
  }
  .dest {
    width: 100px;
    p{
      width: 100%;
    }
  }
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
  margin-bottom: 20px;
}
.shipping{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.tips{
  color: #FF0000;
  margin-left: 10px;
  cursor: pointer;
}
.red{
  color: #FF0000;
}
</style>